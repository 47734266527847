<template>
  <div>
    <AppTitle titulo="Redefinir Senha" />

    <div class="reset-password-container">
      <form @keyup.enter="sendResetPasswordData">
        <div class="reset-password-form-container" v-if="validHash && !shouldRenderSuccessMessage">
          <div class="request-content">
            <b-field
              label="Nova senha"
              :type="passwordValidation.errors.length && data.newPassword ? 'is-danger' : ''"
            >
              <b-input
                password-reveal
                type="password"
                placeholder="Informe a nova senha"
                v-model="$v.data.newPassword.$model"
              />
            </b-field>

            <b-notification
              type="is-danger"
              has-icon
              aria-close-label="Close notification"
              role="alert"
              v-if="passwordValidation.errors.length && data.newPassword"
              :closable="false"
            >
              <li v-for="error in passwordValidation.errors" :key="error.id">
                {{ error.message }}
              </li>
            </b-notification>

            <b-field
              label="Confirmar nova senha"
              :type="campoInvalido('confirmNewPassword', 'data') ? 'is-danger' : ''"
              :message="
                campoInvalido('confirmNewPassword', 'data')
                  ? 'As novas senhas devem ser idênticas'
                  : ''
              "
            >
              <b-input
                password-reveal
                type="password"
                placeholder="Confirme a nova senha"
                v-model="$v.data.confirmNewPassword.$model"
              />
            </b-field>

            <div>
              <a
                :class="[
                  'button is-block is-primary is-medium save-button',
                  loading.resetPassword ? 'is-loading' : '',
                ]"
                @click.prevent.stop="sendResetPasswordData"
                >Enviar</a
              >
            </div>
          </div>
        </div>
      </form>

      <div v-if="!validHash && loaded" class="invalid-link-message">
        <AppMessage>
          <div slot="message-icon">
            <b-icon type="is-danger" icon="close-circle-outline" size="is-large"></b-icon>
          </div>

          <p slot="message-title">
            Link indisponível
          </p>

          <div slot="message">
            Essa redefinição de senha não é válida. Por favor, solicite novamente a redefinição de
            senha.
          </div>
        </AppMessage>

        <router-link :to="{ name: 'ForgotPassword' }">
          <a class="button is-primary is-medium save-button">Redefinir Senha</a>
        </router-link>
      </div>

      <div v-if="shouldRenderSuccessMessage" class="success-message">
        <AppMessage>
          <div slot="message-icon">
            <b-icon type="is-success" icon="check-circle-outline" size="is-large"></b-icon>
          </div>

          <p slot="message-title">Senha alterada com sucesso!</p>

          <div slot="message">Agora, você pode logar com sua nova senha.</div>
        </AppMessage>

        <router-link :to="{ name: 'Login' }">
          <a class="button is-block is-primary is-large save-button">Logar</a>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required, sameAs } from 'vuelidate/lib/validators';

import AppMessage from '@/components/AppMessage';
import AppTitle from '@/components/AppTitulo';
import formValidation from '@/mixins/formulario';
import { passwordRules } from '@/utils/data';
import { resetPassword } from '@/resources/users/index';

export default {
  name: 'ResetPassword',
  mixins: [formValidation],
  components: {
    AppMessage,
    AppTitle,
  },
  data() {
    const data = {
      newPassword: '',
      confirmNewPassword: '',
    };

    const loading = {
      general: false,
      resetPassword: false,
    };

    return {
      data,
      loading,
      loaded: false,
      shouldRenderSuccessMessage: false,
      rules: [...passwordRules],
    };
  },
  validations: {
    data: {
      newPassword: { required },
      confirmNewPassword: {
        sameAsPassword: sameAs('newPassword'),
      },
    },
  },
  computed: {
    ...mapGetters({ validHash: 'getResetPasswordHash' }),
    passwordValidation() {
      const password = this.data.newPassword;

      const errors = [];
      this.rules.forEach(condition => {
        if (!condition.regex.test(password)) {
          errors.push(condition);
        }
      });

      if (!errors.length) {
        return { valid: true, errors };
      }

      return { valid: false, errors };
    },
  },
  methods: {
    ...mapActions(['validatePasswordResetHash']),
    async sendResetPasswordData() {
      if (!this.validar('data') || !this.passwordValidation.valid) {
        return;
      }

      this.loading.resetPassword = true;
      try {
        const response = await resetPassword({
          senha: this.data.newPassword,
          confirmarSenha: this.data.confirmNewPassword,
          hashRedefinicaoSenha: this.$route.query.hash,
        });
        this.data.newPassword = '';
        this.data.confirmNewPassword = '';
        const {
          status,
          data: { type },
        } = response;
        if (status === 201 && type === 'SenhaAlterada') {
          this.shouldRenderSuccessMessage = true;
        }
      } catch (error) {
        this.$alerta('A senha não pode ser alterada', 'is-danger');
      } finally {
        this.loading.resetPassword = false;
      }
    },
    async validateHash() {
      try {
        await this.validatePasswordResetHash({ hashRedefinicaoSenha: this.$route.query.hash });
      } catch (error) {
        if (
          error.response &&
          error.response.data.type &&
          error.response.data.type !== 'TempoExpirado' &&
          error.response.data.type !== 'HashInexistente'
        ) {
          this.$alerta(error.message, 'is-danger');
        }
      }
      this.loaded = true;
    },
  },
  created() {
    this.validateHash();
  },
};
</script>

<style lang="scss" scoped>
.box-subtitle {
  margin-bottom: 0.5rem;
}

.invalid-link-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 950px;
  padding: 20vh 10vw;
  text-align: center;
}

.message-title {
  margin-top: 1rem;
}

.reset-password-container {
  align-items: center;
  display: flex;
  height: 65vh;
  justify-content: center;
}

.reset-password-form-container {
  align-items: center;
  display: flex;

  justify-content: center;
  min-width: 350px;
}

.send-button {
  margin-top: 1.5rem;
}

.success-message {
  justify-content: center;
  text-align: center;
}

@media (min-width: 550px) {
  .request-content {
    min-width: 450px;
  }
}
</style>
